<style>
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #7253cf;
  color: #fff;
}

.vue__time-picker input.display-time {
  border: 1px solid #0000005e;
  width: 13.7em;
  height: 2.5em;
  padding: 0.3em 0.5em;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
}
</style>
<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="520px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-else>
        <v-card-title
          class="py-0 pt-7"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            large
            color="white"
            style="cursor: pointer; position: relative"
            @click="toggleAssemblyModal({ show: false })"
            v-if="$vuetify.breakpoint.smAndUp"
            >mdi-close
          </v-icon>
          <v-icon
            text
            large
            color="white"
            style="
              font-size: 30px;
              cursor: pointer;
              position: relative;
              bottom: 120px;
              left: 93%;
            "
            @click="toggleAssemblyModal({ show: false })"
            v-if="$vuetify.breakpoint.xsOnly"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form lazy-validation ref="assemblyForm">
            <v-row no-gutters justify="center">
              <v-col
                cols="6"
                class="pr-2"
                v-if="
                  (this.adminAccess != 'Super Admin' &&
                    this.adminAccess != 'Event Support Staff' &&
                    this.adminAccess != 'Sales Representative') ||
                  this.type != 'add'
                "
              >
                <v-text-field
                  label="Meeting Place"
                  outlined
                  dense
                  v-model="school"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                v-if="
                  (this.adminAccess != 'Super Admin' &&
                    this.adminAccess != 'Event Support Staff' &&
                    this.adminAccess != 'Sales Representative') ||
                  this.type != 'add'
                "
              >
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="meeting_time"
                  placeholder="Meeting Time"
                  close-on-complete
                ></vue-timepicker>
              </v-col>
              <v-col
                cols="12"
                v-if="
                  (this.adminAccess != 'Super Admin' &&
                    this.adminAccess != 'Event Support Staff' &&
                    this.adminAccess != 'Sales Representative') ||
                  this.type != 'add'
                "
              >
                <v-text-field
                  label="Meeting Address"
                  outlined
                  dense
                  v-model="address"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="pr-2"
                v-if="
                  (this.adminAccess != 'Super Admin' &&
                    this.adminAccess != 'Event Support Staff' &&
                    this.adminAccess != 'Sales Representative') ||
                  this.type != 'add'
                "
              >
                <v-text-field
                  outlined
                  dense
                  v-model="name"
                  label="Driver Name"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                v-if="
                  (this.adminAccess != 'Super Admin' &&
                    this.adminAccess != 'Event Support Staff' &&
                    this.adminAccess != 'Sales Representative') ||
                  this.type != 'add'
                "
              >
                <v-text-field
                  outlined
                  dense
                  v-model="mobile"
                  label="Driver Cell"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pr-2">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formatedDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Assembly Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      :rules="[rules.required]"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              justify="center"
              v-if="
                (this.adminAccess != 'Super Admin' &&
                  this.adminAccess != 'Event Support Staff' &&
                  this.adminAccess != 'Sales Representative') ||
                this.type != 'add'
              "
            >
              <v-col cols="6" class="pr-2">
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="start_time"
                  placeholder="Game Start Time"
                  close-on-complete
                ></vue-timepicker>
              </v-col>
              <v-col cols="6" class="pr-2">
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="end_time"
                  placeholder="Game End Time"
                  close-on-complete
                ></vue-timepicker>
              </v-col>
            </v-row>
            <br />
            <v-row no-gutters justify="center">
              <v-col
                cols="6"
                class="pr-2"
                v-if="
                  (this.adminAccess != 'Super Admin' &&
                    this.adminAccess != 'Event Support Staff' &&
                    this.adminAccess != 'Sales Representative') ||
                  this.type != 'add'
                "
              >
                <v-text-field
                  outlined
                  dense
                  v-model="noOfStudents"
                  label="Number of Students"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="pr-2"
                v-if="
                  (this.adminAccess != 'Super Admin' &&
                    this.adminAccess != 'Event Support Staff' &&
                    this.adminAccess != 'Sales Representative') ||
                  this.type != 'add'
                "
              >
                <v-text-field
                  outlined
                  dense
                  v-model="noOfAssemblies"
                  label="Number of Assemblies"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6" class="pr-2">
                <v-autocomplete
                  label="Team"
                  outlined
                  dense
                  v-model="team"
                  :items="teamNameList"
                  class="formFields"
                  item-text="team_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" v-if="this.team == null">
                <v-combobox
                  v-model="players"
                  :items="playerList"
                  label="Player"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  multiple
                  outlined
                  dense
                ></v-combobox>
              </v-col>
              <v-col cols="12">
                <div v-if="this.type != 'add'">
                  <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel
                      v-for="(school1, index) in schools"
                      :key="index"
                    >
                      <v-expansion-panel-header>
                        <h3>School #{{ index + 1 }}</h3>
                        <!--   <v-icon
                        size="20"
                        color="red"
                        @click.stop="deleteStudent(school1.id)"
                        >mdi-delete</v-icon
                      >
                      <v-btn
                        class="submit-btn clearForm"
                        dark
                        color="#38227A"
                        width="22%"
                        @click.stop="removeSchool(index)"
                        >Clear Form</v-btn
                      >-->
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row no-gutters justify="center">
                          <v-col cols="12" class="pr-2">
                            <v-text-field
                              outlined
                              dense
                              v-model="school1.name"
                              color="#7253CF"
                              class="formFields"
                              label="Name of School"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters justify="center">
                          <v-col cols="6" class="pr-2">
                            <vue-timepicker
                              format="hh:mm A"
                              v-model="school1.time"
                              class="formFields"
                              placeholder="Start Time"
                              auto-scroll
                              close-on-complete
                              input-width="100%"
                              label="Start Time"
                            ></vue-timepicker>
                          </v-col>
                          <v-col cols="6" class="pr-2">
                            <vue-timepicker
                              format="hh:mm A"
                              v-model="school1.endtime"
                              class="formFields"
                              placeholder="End Time"
                              auto-scroll
                              close-on-complete
                              label="End Time"
                              input-width="100%"
                            ></vue-timepicker>
                          </v-col>
                        </v-row>
                        <br />
                        <v-row no-gutters justify="center">
                          <v-col cols="6" class="pr-2">
                            <v-text-field
                              outlined
                              dense
                              v-model="school1.address"
                              color="#7253CF"
                              class="formFields"
                              label="Address"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="pr-2">
                            <v-text-field
                              outlined
                              dense
                              v-model="school1.location"
                              color="#7253CF"
                              class="formFields"
                              label="Location within school"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters justify="center">
                          <v-col cols="6" class="pr-2">
                            <v-text-field
                              outlined
                              dense
                              v-model="school1.principalName"
                              color="#7253CF"
                              class="formFields"
                              label="Principal Name"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6" class="pr-2">
                            <v-text-field
                              outlined
                              dense
                              v-model="school1.email"
                              color="#7253CF"
                              class="formFields"
                              :rules="[rules.email]"
                              label="Email Address Of Principal"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters justify="center">
                          <v-col cols="6" class="pr-2">
                            <v-text-field
                              outlined
                              dense
                              v-model="school1.phone"
                              color="#7253CF"
                              class="formFields"
                              label="Phone"
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="6" class="pr-2">
                            <v-text-field
                              outlined
                              dense
                              v-model="school1.no_of_student"
                              type="number"
                              color="#7253CF"
                              class="formFields"
                              label="Number Of Student"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters justify="center">
                          <v-col cols="6" class="pr-2">
                            <v-text-field
                              outlined
                              dense
                              v-model="school1.grade"
                              color="#7253CF"
                              class="formFields"
                              label="Grades"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="6" class="pr-2">
                            <v-autocomplete
                              label="Assembly/Cafeteria visit"
                              v-model="school1.assembly"
                              outlined
                              dense
                              :items="assemblyOptions"
                              class="formFields"
                              color="#7253CF"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row no-gutters justify="center">
                        <v-col cols="12" class="pl-2">
                <label>Note</label>
                <quillEditor v-model="school1.note" :options="editorOption" />
              </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <!-- Other fields... -->
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            class="dialogAction-btnText text-capitalize px-7"
            height="45"
            color="#38227A"
            :loading="formLoading"
            @click="submitForm"
          >
            <span>{{ actionText }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_ADMIN_GET_SCHOOL_LIST,
  ASSEMBLY_SCHEDUAL_API_POST,
  ASSEMBLY_SCHEDUAL_API_PATCH,
  ASSEMBLY_SCHEDUAL_API_GET,
  API_EVENT_TEAM_PLAYER_LIST_GET,
  API_ADMIN_TEAM_NAME_LIST,
  PAID_ASSEMBLY_SCHOOL_GET_API,
  PAID_ASSEMBLY_SCHOOL_DELETE_API,
} from "@/constants/APIUrls";
import Helper from "@/helper";
import RULES from "@/common/fieldRules";
import moment from "moment";
export default {
  name: "AssemblySchedualModal",
  components: { VueTimepicker,quillEditor },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      panel: [0], // Panel 0 is expanded by default
      rules: RULES,
      menu1: false,
      menu2: false,
      menu3: false,
      loading: false,
      formLoading: false,
      school: "",
      schoolList: [],
      address: "",
      date: "",
      start_time: "08:00 AM",
      end_time: "08:00 AM",
      noOfStudents: null,
      noOfAssemblies: null,
      meeting_time: "",
      name: "",
      mobile: null,
      email: "",
      notes: "",
      players: [],
      playerList: [],
      team_name: "",
      team_id: "",
      team: "",
      school1: {},
      school2: {},
      schools: [
        {
          time: "08:00 AM",
          endtime: "08:00 AM",
          name: "",
          // Other fields...
        },
      ],
      assemblyOptions: ["Assembly", "Cafeteria"],
      lisssst: [{ id: null, team_name: "unassigned" }],
      demoTeamList: [this.lisssst + this.teamNameList],
      teamNameList: [],
      editorOption: {
        placeholder: "Message Content",
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            [{ direction: "rtl" }], // text direction
            ["clean"],
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getShow: "gameManagement/getAssembly",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.getShow;
      },
      set(value) {
        return this.toggleAssemblyModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.addEditAssemblyModal.type;
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add new Assembly Schedule";
        case "edit":
          return "Update Assembly Schedule";
        default:
          return "";
      }
    },
    formatedDate: {
      get() {
        if (this.date) {
          return moment(this.date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
    },
    formattedTime: {
      get() {
        if (this.start_time) {
          return Helper.timeFormatter(this.start_time);
        } else {
          return "";
        }
      },
    },
    formattedEndTime: {
      get() {
        if (this.end_time) {
          return Helper.timeFormatter(this.end_time);
        } else {
          return "";
        }
      },
    },
    actionText() {
      if (this.type === "add") {
        return "Add new Assembly";
      } else {
        return "Save";
      }
    },
    toastMessage() {
      if (this.type === "add") {
        return "Assembly Schedual Added";
      } else {
        return "Assembly Schedual Updated";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAssemblyModal: "gameManagement/toggleAssembly",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      // this.getSchoolList();
      this.getTeamList();
      this.getteamPlayer();
      if (this.type === "edit") {
        this.getDetail();
        this.getSchoolDetail();
      }
    },
    closeModal() {
      this.loading = false;
      this.formLoading = false;
      this.school = "";
      this.schoolList = [];
      this.address = "";
      this.date = "";
      this.start_time = "";
      this.end_time = "";
      this.noOfStudents = null;
      this.noOfAssemblies = null;
      this.name = null;
      this.mobile = null;
      this.email = null;
      this.notes = "";
      this.team = "";
      this.players = "";
      this.schools = [];
    },
    getSchoolList() {
      this.loading = true;
      const successHandler = (res) => {
        const data = res.data;
        this.schoolList = data.school_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        if (this.type === "add") {
          this.loading = false;
        } else {
          this.getDetail();
        }
      };
      let formData = {};
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    setplayers(playerList) {
      const self = this;
      if (this.players[0]) {
        let res = [];
        res = playerList.filter((el) => {
          return !self.filterList.find((element) => {
            return element.id === el.id;
          });
        });
        self.players = self.players.concat(res);
      } else {
        this.players = playerList;
        this.filterList = playerList;
      }
    },
    getteamPlayer() {
      const successHandler = (res) => {
        this.playerList = res.data.player_list;
        //   this.setplayers(res.data.player_list);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {};
      let formData = {};
      if (this.team_name) {
        formData["team_name"] = this.team_name;
      }
      Axios.request_GET(
        API_EVENT_TEAM_PLAYER_LIST_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getTeamList() {
      const successHandler = (res) => {
        const teamnaeee = res.data.team_name_list;
        const teamlistsss = [{ id: null, team_name: "Custom" }];
        this.teamNameList = teamlistsss.concat(teamnaeee);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {};
      let formData = {};
      Axios.request_GET(
        API_ADMIN_TEAM_NAME_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getDetail() {
      const successHandler = (res) => {
        console.log(res);
        this.school = res.data.assemble_detail.school;
        this.address = res.data.assemble_detail.address;
        this.date = res.data.assemble_detail.date;
        this.start_time = Helper.convertFrom24To12Format(
          res.data.assemble_detail.start_time
        );
        this.end_time = Helper.convertFrom24To12Format(
          res.data.assemble_detail.end_time
        );
        this.noOfStudents = res.data.assemble_detail.total_student;
        this.noOfAssemblies = res.data.assemble_detail.total_assemblies;
        this.name = res.data.assemble_detail.driver_name;
        this.mobile = res.data.assemble_detail.phone;
        this.email = res.data.assemble_detail.email;
        this.notes = res.data.assemble_detail.note;
        this.team = res.data.assemble_detail.team;
        if (res.data.assemble_detail.meeting_time) {
          this.meeting_time = Helper.convertFrom24To12Format(
            res.data.assemble_detail.meeting_time
          );
        }
        if (res.data.assemble_detail.player_list != null) {
          let playerIdList = res.data.assemble_detail.player_list.map(function (
            obj
          ) {
            return obj.id;
          });
          this.players = this.playerList.filter((k) =>
            playerIdList.includes(k.id)
          );
        }
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["assemble_id"] =
        this.$store.state.gameManagement.addEditAssemblyModal.assemblyID;
      Axios.request_GET(
        ASSEMBLY_SCHEDUAL_API_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm1() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
        // this.$emit("reload");
        this.toggleAssemblyModal({ show: false });
        this.showToast({
          message: this.toastMessage,
          color: "s",
        });
        this.formLoading = false;
        location.reload();
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
        this.formLoading = false;
      };
      let formData = {};
      formData["game"] = this.$route.query.game_id;
      if (this.school) {
        formData["school"] = this.school;
      }
      if (this.address) {
        formData["address"] = this.address;
      }
      if (this.formatedDate) {
        formData["date"] = this.formatedDate;
      }
      if (this.start_time) {
        formData["start_time"] = Helper.convertTime12To24(this.start_time);
      } else {
        formData["start_time"] = "08:00 AM";
      }
      if (this.end_time) {
        formData["end_time"] = Helper.convertTime12To24(this.end_time);
      } else {
        formData["end_time"] = "08:00 AM";
      }
      if (this.noOfStudents) {
        formData["total_student"] = this.noOfStudents;
      }
      if (this.noOfAssemblies) {
        formData["total_assemblies"] = this.noOfAssemblies;
      }
      if (this.name) {
        formData["driver_name"] = this.name;
      }
      if (this.meeting_time) {
        formData["meeting_time"] = Helper.convertTime12To24(this.meeting_time);
      }
      if (this.mobile) {
        formData["phone"] = this.mobile;
      }
      if (this.email) {
        formData["email"] = this.email;
      }
   
      this.playersCopy = [];
      Object.keys(this.players).forEach((key) =>
        this.playersCopy.push(this.players[key].id)
      );

      if (this.team) {
        formData["team"] = this.team;
        formData["players"] = [];
      } else {
        formData["team"] = null;
        formData["players"] = this.playersCopy;
      }
      if (this.type === "add") {
        Axios.request_POST(
          ASSEMBLY_SCHEDUAL_API_POST,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        formData["id"] =
          this.$store.state.gameManagement.addEditAssemblyModal.assemblyID;
        Axios.request_PATCH(
          ASSEMBLY_SCHEDUAL_API_PATCH,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },

    submitForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
        this.toggleAssemblyModal({ show: false });
        this.showToast({
          message: "Assembly Schedule Submitted",
          color: "s",
        });
        location.reload();
        this.formLoading = false;
      };

      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
        this.formLoading = false;
      };
      this.ticketCopy = [];
      Object.keys(this.players).forEach((key) =>
        this.ticketCopy.push(this.players[key].id)
      );

      if (this.type === "add") {
        let formData = {
          game: this.$route.query.game_id,
          date: this.formatedDate,
          start_time: Helper.convertTime12To24(this.start_time || "08:00 AM"),
          end_time: Helper.convertTime12To24(this.end_time || "08:00 AM"),
          team: this.team,
          players: this.ticketCopy,
          meeting_time: Helper.convertTime12To24(
            this.meeting_time || "08:00 AM"
          ),
        };
        Axios.request_POST(
          ASSEMBLY_SCHEDUAL_API_POST,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        let formData = {
          game: this.$route.query.game_id,
          school: this.school,
          address: this.address,
          date: this.formatedDate,
          start_time: Helper.convertTime12To24(this.start_time || "08:00 AM"),
          end_time: Helper.convertTime12To24(this.end_time || "08:00 AM"),
          total_student: this.noOfStudents,
          total_assemblies: this.noOfAssemblies,
          driver_name: this.name,
          phone: this.mobile,
          team: this.team,
          players: this.ticketCopy,
          sales_rep: this.salesRep,
          event_support: this.eventSupport,
          organization: this.organization,
          designation: this.designation,
          assemble_status: this.assembleStatus,
          city: this.city,
          state: this.state,
          approved: this.adminAccess == "game" ? false : true,
          email: this.email,

          meeting_time: Helper.convertTime12To24(this.meeting_time || "08:00"),
          assemble_school_groups: this.schools.map((school1) => ({
            school_name: school1.name,
            start_time: Helper.convertTime12To24(school1.time || "08:00 AM"),
            end_time: Helper.convertTime12To24(school1.endtime || "08:00 AM"),
            school_address: school1.address,
            location_in_school: school1.location,
            principal_name: school1.principalName,
            principal_email: school1.email,
            principal_phone: school1.phone,
            number_of_students: school1.no_of_student,
            grades: school1.grade,
            note:school1.note,
            visit: school1.assembly,
          })),
        };
        formData["id"] =
          this.$store.state.gameManagement.addEditAssemblyModal.assemblyID;
        Axios.request_PATCH(
          ASSEMBLY_SCHEDUAL_API_PATCH,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    getSchoolDetail() {
      const successHandler = (res) => {
        console.log(res);

        // Initializing empty arrays to store the details from the group_detail array
        this.schools = [];
        this.addresses = [];
        this.dates = [];
        this.meeting_times = [];
        this.start_times = [];
        this.end_times = [];
        this.noOfStudentsList = [];
        this.noOfAssembliesList = [];
        this.names = [];
        this.mobiles = [];
        this.emails = [];
        this.notes = [];
        this.teams = [];

        res.data.group_detail.forEach((detail) => {
          this.schools.push({
            id: detail.id,
            name: detail.school_name,
            address: detail.school_address,
            time: Helper.convertFrom24To12Format(detail.start_time),
            endtime: Helper.convertFrom24To12Format(detail.end_time),
            location: detail.location_in_school,
            principalName: detail.principal_name,
            email: detail.principal_email,
            phone: detail.principal_phone,
            no_of_student: detail.number_of_students,
            grade: detail.grades,
            note:detail.note,
            assembly: detail.visit,
          });

          this.loading = false;
        });
      };

      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };

      let formData = {};
      formData["assemble_id"] =
        this.$store.state.gameManagement.addEditAssemblyModal.assemblyID;
      Axios.request_GET(
        PAID_ASSEMBLY_SCHOOL_GET_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    deleteStudent(id) {
      const self = this;
      self.formLoading = true;

      const successHandler = (res) => {
        const data = res.data;
        self.formLoading = false;
        console.log(data);
        self.$emit("reload");
        this.getSchoolDetail();
        this.showToast({
          message: "School Deleted",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        const data = res;
        self.formLoading = false;
        console.log(data);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["id"] = id; // Use the passed `id` parameter here
      Axios.request_DELETE(
        PAID_ASSEMBLY_SCHOOL_DELETE_API,
        formData,
        {},

        successHandler,
        failureHandler,
        false
      );
    },
    removeSchool(index) {
      this.schools.splice(index, 1);
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
@media (min-width: 1281px) {
  .dialogTitle-text {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 315%;
    color: #ffffff;
  }
}
@media (max-width: 1281px) {
  .dialogTitle-text {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 315%;
    color: #ffffff;
  }
}
.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
